export default async (user, answers, cb) => {
    const url = `${process.env.REACT_APP_REST_API_URL}/user/${user}`

    const answersObj = {
        answers: { ...answers,date: Date.now()}
        //   "spread": precentArr,
        //   "rating": rating,
        //   "date": Date.now()
        }
      console.log(answersObj)
  
      fetch(url, {
        method:"PATCH",
        headers: {
          "Content-Type" : "application/json"
        },
        body: JSON.stringify(answersObj)
      })
      .then(res => res.json())
      .then(json => cb(7)) //SET PROGRESS
      .catch(err => {
        console.log(err)
      })
}



//     // let foundUser = {};
//     let fetchedData;
//     try {
//         await fetch(url)
//             .then(resp => resp.json())
//             .then(data => {
//                 const foundUser = {
//                     name:data.name,
//                     id:data._id
//                 }
//                 console.log(data)
//                 fetchedData = data;
//             })
//             .catch(err => {
//                 console.log("ERROR" + err)
//                 fetchedData = false
//             })

//     } catch(err) {
//         console.log("TRY ERROR" + err)
//         fetchedData = false
//     }
//     return fetchedData
    
// }