export default async (company) => {

    const url = `${process.env.REACT_APP_REST_API_URL}/company/${company}`
    let fetchedData = {message:null,data:null};
    const fetchOptions = {
        method: 'PATCH',
    };

    try {
        await fetch(url, fetchOptions).
        then(response => response.json()).
        then(data => fetchedData = data)
    } catch(err) {
        console.error(err)
        fetchedData = err
    }
    return fetchedData

}   