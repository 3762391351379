import React, { useState, useEffect,useCallback } from 'react';
import styled from 'styled-components';
import RootContainer from "../styled/RootContainer"
import getAllusers from '../functions/getAllusers';
import getOneCompany from '../functions/getOneCompany'
import getAllUsersFromCompany from '../functions/getAllUsersFromCompany';
// import UserBar from '../components/UserBar';
import DashboardGraphs from '../components/DashboardGraphs';
import UserBars from '../components/UserBars';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { Link, useParams } from 'react-router-dom';
import TeamSelector from '../components/TeamSelector';
import { FiExternalLink } from "react-icons/fi";
import MenuBox from '../components/MenuBox';


const DashBoardContainer = styled(RootContainer)`
    max-width:90vw;
`


function LederDashboard() {

    const [allUsers, setAllUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedTeam, setselectedTeam] = useState();
    const [hasFetched, sethasFetched] = useState(false);
    const [selectIndex, setselectIndex] = useState(0);
    const [lang, setlang] = useState("EN");
    const [teamList, setTeamList] = useState([]);

    const { company } = useParams();

    console.log(company)
    useEffect(() => {
        // setselectIndex(0)
        if(!hasFetched && company) {
            const allUsersPromise = getAllUsersFromCompany(company)
            const companyPromise = getOneCompany(company)
    
            allUsersPromise.then(resp => {
                if(resp) {
                    console.log(resp);
                    setAllUsers(resp)
                    setSelectedUsers(resp)
                    sethasFetched(true)
                } 
    
            })
            companyPromise.then(resp => {
                // console.log(resp.teams);
                setTeamList(resp.teams)
            })
        }
        

        window.addEventListener("keydown", handleKeyDown)
        return () => {}

    }, []);

    useEffect(() => {
        // const filteredUsers = 
        if(teamList && selectedTeam) {
            const getTeam = teamList.filter(t => t.name === selectedTeam)
            const getteamusers = getTeam[0].members.map(m => m)
            const getusers = allUsers.filter(u => getteamusers.includes(u._id))
            console.log(getusers);
            setSelectedUsers(getusers)
        }
        if(teamList && !selectedTeam) {
            setSelectedUsers(allUsers)
        }
        setselectIndex(0) 

    }, [selectedTeam]);
    

    const handleKeyDown = useCallback((e) => {
        e.preventDefault()
        let direction = e.key === "ArrowUp" ? -1 : e.key === "ArrowDown" ? 1 : 0;

        const newIndex = direction + selectIndex
        if(newIndex < 0) {
            setselectIndex(allUsers.length - 1)
            return
        }
        switch (true) {
            case newIndex < 0:
                setselectIndex(allUsers.length - 1)
                break;
            case newIndex > (allUsers.length - 1):
                setselectIndex(0)
                break;
            default:
                setselectIndex(newIndex)
                break;
        }

    })

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
    
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
      }, [handleKeyDown]);





    return (
        <DashBoardContainer>
            <MenuBox>
                <div>
                    <h1>DASHBOARD</h1>
                    <h2>{company}</h2>
                </div>
                <LanguageSwitcher lang={lang} setlang={setlang} pos={["bottom:3rem;","left:3rem"]}/>
                <Link to={`/manage_users/${company}`}>User Managment <FiExternalLink /></Link>
                
                <TeamSelector 
                    teams={teamList}
                    select={setselectedTeam}
                    selectedTeam={selectedTeam}
                    />
            </MenuBox>
            {hasFetched ? <DashboardGraphs lang={lang} allUsers={selectedUsers} selectIndex={selectIndex} /> : null}
            <UserBars 
                lang={lang}
                allUsers={selectedUsers} 
                hasFetched={hasFetched} 
                setIndex={setselectIndex}
                selectIndex={selectIndex}
            />
            {/* <UserBarContainer> 
                {userBars}

            </UserBarContainer> */}



        </DashBoardContainer>
    );
}

export default LederDashboard;