export default async (user) => {
    const url = `${process.env.REACT_APP_REST_API_URL}/user/${user}`

    // let foundUser = {};
    let fetchedData;
    try {
        await fetch(url)
            .then(resp => resp.json())
            .then(data => {
                const foundUser = {
                    name:data.name,
                    id:data._id
                }
                console.log(data)
                fetchedData = data;
            })
            .catch(err => {
                console.log("ERROR" + err)
                fetchedData = false
            })

    } catch(err) {
        console.log("TRY ERROR" + err)
        fetchedData = false
    }
    return fetchedData
    
}