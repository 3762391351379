import React from 'react';
import graphColors from '../data/graphColors';
import BoxChart from './BoxChart';
import LineGraph from './LineChart';
import moment from 'moment'
import makeCurveData from '../functions/makeCurveData'
import makeGraphDataSet from '../functions/makeGraphDataSet';
import RadialBar from './RadialBar';
import styled from 'styled-components';
import { device } from '../styled/device';
// import testdata from '../data/testgraph.json'

const labels = {
    "EN": [
        "Demotivation",
        "Learning",
        "Mastering",
        "Motivation",
        "Well-being"
    ],
    "DK": [
        "Demotivation",
        "Læring",
        "Mastering",
        "Motivation",
        "Trivsel"
    ]
}

const RadialsContainer = styled.div`
    width:${props => props.small ?  "50%" : "100%"};
    display:flex;
    flex-flow:wrap;
    height:${props => props.small ?  "50px" : "300px"};
    margin:0 auto;
    justify-content:center;

    @media ${device.mobileL} {
        width: 100%;
    }

`

function Graph({user,lang,small}) {

    // const {user, lang, small} = props
    
    console.log(user);

    
    const results = {
        "1":makeCurveData(user.answers,0,3),
        "2":makeCurveData(user.answers,2,3),
        "3":makeCurveData(user.answers,0,1),
        "4":makeCurveData(user.answers,1,2),
        "5":user.answers.map(answ => ({value:answ.rating*10,date:moment.utc(answ.date,"YYYY-MM-DD\THH:mm:ss\Z").format("DD-MM-YYYY")}))
    }

    const currentResult = Object.keys(results).map((id,index) => {
        // if(id === "trivsel") return
        console.log(results[id])
        return ({
            id:labels[lang][index],
            data: [{
                x:"%",
                y:results[id][results[id].length - 1].value
            }],
            color:graphColors[index],
            trend: results[id].length > 1 ? results[id][results[id].length - 2].value : null

            // [id]:results[id][results[id].length - 1].value, //GET LATEST RESULT
            // label:labels[lang][index]
        })
    })
    // const currentResult = Object.keys(results).map((id,index) => {
    //     // if(id === "trivsel") return
    //     return ({
    //         id:id,
    //         [id]:results[id][results[id].length - 1].value, //GET LATEST RESULT
    //         color:graphColors[index],
    //         label:labels[lang][index]
    //     })
    // })
    //REMOVE TRIVSEL FROM CURRENT RESULT
    currentResult.pop()

    const radials = currentResult.map((r,index) => {

        return (
            <RadialBar key={index} data={[r]} small={small}/>
        )
    })


    // const d = `M0,${100 - results.motivation[0]} ${results.motivation.map((val,index) => {
    //     if(index === 0) return
    //     return `L${index*10},${100 - val}`
    // })}`


    const data = Object.keys(results).map((key,index) => {
        const dataSet = makeGraphDataSet(labels[lang][parseInt(key)-1],results[key],graphColors[index]);
        return dataSet
    })


    console.log(data);



    return (
        <div>
            {/* <svg width="100" height="100" viewBox="0 0 100 100">

                <path 
                    d={d}
                // d="
                //     M0,50
                //     L5,100
                //     L10,20
                // "
                style={{stroke: "black", fill: "none"}}/>
            </svg> */}
            <RadialsContainer small={small}>
                {radials}

            </RadialsContainer>
            {/* <RadialBar data={currentResult} /> */}
            {/* <BoxChart lang={lang} data={currentResult} active={true}/> */}
            {user.answers.length > 1 ? <LineGraph lang={lang} data={data}/> : null}
        </div>
    );
}

export default Graph;