import styled from "styled-components"
import colors from '../data/colors';

const TextBox = styled.div`
  /* position: absolute; */
  top:1rem;
  left:0;
  max-width:40rem;
  width:calc(100% - 7rem);
  /* background-color: red; */
  height: 50vh;
  margin: 0 auto;
  transform:translate(0,25%);
  background-color:${colors[1]} ;
  border-radius:15px;
  box-shadow:2px 2px 9px ${colors[2]};
  padding:3rem;
  color:white;
`

export default TextBox