import React, { useState } from 'react';
import CardStyled from '../styled/CardStyled';

function AddToCompany({handleAdd, setAddState,mode}) {

    const [name, setname] = useState("");
    const [open, setopen] = useState("");

    setTimeout(() => {
        setopen("open")
    }, 10);

    function handleChange(e) {
        setname(e.target.value)
    }

    function handleSubmit(e) {
        const teamObj = [
            {name:name}
        ]
        e.preventDefault()
        const data = new FormData(e.target);
        const dataObj = Object.fromEntries(data.entries())
        // console.log(Object.fromEntries(data.entries()));
        handleAdd([dataObj])
        setAddState(false)
    }
    return (
        <CardStyled className={`input  ${open} ${mode}`}>
            <h1>ADD</h1>
            <form action="" onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    name="name"
                    id="name"
                    placeholder="name" 

                />
                {mode === "user" ? 
                    <input 
                        type="text" 
                        name="email"
                        id="email"
                        placeholder="email" 

                    />
                : null
                }
                <input 
                    type="submit"

                    // onClick={handleClick}
                    value="GEM"
                />

            </form>
        </CardStyled>

    );
}

export default AddToCompany;