export default (e) => {
    const text = e.target.innerHTML;
    const node = e.target;

    if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
    }
    const selection = window.getSelection();
    document.execCommand("Copy")
    selection.removeAllRanges();
    console.log("Copied!");
    node.innerHTML = "Copied!"
    setTimeout(() => { node.innerHTML = text }, 1000);

}