import React, {useEffect, useState} from 'react';
import answers from "../data/answers.json"
import ResultContainer from '../styled/AnimatedContainer';


function Result(props) {

    const [active, setactive] = useState(false);
    const [ratingIndex, setratingIndex] = useState();
    useEffect(() => {
        const rating = props.rating < 4 ? 0 : props.rating < 8 ? 1 : 2;
        setratingIndex(rating)
        setTimeout(() => {
            setactive(true)
            
        },500)
    }, []);
    
    // const ratingIndex = props.rating < 4 ? 0 : props.rating < 8 ? 1 : 3 //0-3
    console.log(ratingIndex);
    // 0 (1, 2, 3)
    // 1 (4, 5, 6, 7)
    // 3 (8, 9, 10)

    return (
        <ResultContainer active={active} style={{whiteSpace:"break-spaces"}}>
            <h1>{answers[`${props.result[0]}_${props.lang}`].title}</h1>
            <p>{answers[`${props.result[0]}_${props.lang}`].text[ratingIndex]}</p>
        </ResultContainer>
    );
}

export default Result;