import React, {useState,useEffect} from 'react';
import AnimatedContainer from '../styled/AnimatedContainer';
import Graph from './Graph';
import styled from "styled-components";


const DashboardContainer = styled(AnimatedContainer)`
    max-height:${props => props.active ? "100vh" : "0vh"};
`

function Dashboard(props) {

    const [active, setactive] = useState(false);
    const today = new Date()
    const isoDate = today.toISOString()

    //If there's a new result add it, else just show previous results
    const answersToGraph = props.showOldValues ? [...props.user.answers] : [...props.user.answers, props.answer]

    console.log(isoDate);

    useEffect(() => {
        setTimeout(() => {
            setactive(true)

        },1000)
    }, []);
// console.log(props.user);


    return (
        <DashboardContainer active={active}>
            {/* <h1>DASHBOARD</h1> */}
            <Graph lang={props.lang} user={{...props.user,answers:answersToGraph}} currentAnswer={props.answers}/>
        </DashboardContainer>
    );
}

export default Dashboard;