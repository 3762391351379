import React, {useState, useEffect} from "react"
import { CSVLink, CSVDownload } from "react-csv";
import RootContainer from "../styled/RootContainer"
import TextBox from '../styled/TextBox'
import putUser from '../functions/putUser.js'
import getAllusers from "../functions/getAllusers.js"
import { csv2json } from "csvjson-csv2json";


const AddUsers = () => {

    const [csvFile, setCsvFile] = useState(null)
    const [uploadedFile, setUploadedFile] = useState()
    const [inputText, setInputText] = useState("")
    const [message, setMessage] = useState(null)

    const fileReader = new FileReader();



    function handleChange(e) {
        setInputText(e.target.value)

    }
    async function handleSubmit(e) {
        e.preventDefault()

        // PARSE STRING TO JSON, RETURN ERROR IF INVALID ELSE POST USER
        try {
            // const parsedJSON = JSON.parse(inputText)
            const parsedJSON = csv2json(inputText)
            const promise = putUser(parsedJSON)
            promise.then(resp => {
                console.log(resp)
                if(resp.message) {
                    setMessage(resp.message)

                } else {
                    let messageString = `${resp.created.length} ${resp.created.length > 1 ? "users" : "user"} created`
                    messageString = resp.notCreated.length > 0 ?  `${messageString} \n${resp.notCreated.length} ${resp.notCreated.length > 1 ? "emails" : "email"} already in use` : messageString;
                    setMessage(messageString)

                    // const allUsersPromise = getAllusers()
                    // allUsersPromise.then(response => createCSV(response,resp.notCreated))
                }
            }).then(
                createCSV()
            )
        } catch(err) {
            console.log(err)
            setMessage("Not a proper cvs")
            
        }
        // const parsedJSON = await JSON.parse(inputText)

    }
    function createCSV() {

        const allUsersPromise = getAllusers()
        allUsersPromise.then(data => {

            console.log(data)
            const csv = [["name","email","company","role","_id"]];
            data.forEach(emp => {
                const row = [emp.name,emp.email,emp.company,emp.role,emp._id]
                csv.push(row)
            })
            // if(notCreated) {
            //     notCreated.forEach(emp => {
            //         const row = [emp.name,emp.email,emp.company,emp._id]
            //         csv.push(row)
            //     })
            // }
            setCsvFile(csv)
        })


        // console.log(csv)
        
    }

    function handleUpload(e) {
        // e.preventDefault()
        setUploadedFile(e.target.files[0])
    }

    useEffect(()=> {

        if (uploadedFile) {
            //create event listener for uploaded file to memory 
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                console.log(csvOutput)
                // set file as inputText
                setInputText(csvOutput)
            };
            //set uploaded file to memory
            fileReader.readAsText(uploadedFile);
        }
    },[uploadedFile])

    return(
        <RootContainer>
            <TextBox>
                <form onSubmit={handleSubmit}>
                    <textarea 
                        id="input" 
                        name="input" 
                        rows="12" 
                        cols="40" 
                        placeholder="paste csv in here, or upload a .csv file..."
                        onChange={handleChange}
                        value={inputText}
                    />
                      
                    <input
                        type={"file"}
                        accept={".csv"}
                        onChange={handleUpload}
                        value={null}
                    />
                    <input style={{display:"block", margin:"20px 0"}} type="submit" value="Submit" />                           

                </form>
                <h1 style={{whiteSpace:"pre-wrap"}}>{message}</h1>
                {csvFile ? <CSVLink style={{textDecoration:"none", color:"white"}} data={csvFile}>Download CSV file</CSVLink>: null}
            </TextBox>
            
        </RootContainer>
    )
}

export default AddUsers