import styled from 'styled-components';
import colors from '../data/colors';

const CardStyled = styled.div`
            position:relative;
            height:0rem;
            overflow:hidden;
            height:0;
            padding:0 0.5rem;
            transition:height 0.2s linear, padding 0.2s linear;
            .info {
                opacity:0;
                height:10rem;
                transition:opacity 0.15s linear;
                display:flex;
                flex-direction: column;
                justify-content: space-around;
                p.link{
                    overflow:hidden;
                    display:inline;
                    padding:1rem 0 1rem 1rem;
                    margin-right:2rem;
                }
                i {
                    /* display:inline-block; */

                    
                    font-size:1rem;
                    cursor: pointer;
                    &:hover {
                        color:${colors[2]};
                    }
                    /* i {
                        width:4rem;
                        /* visibility:hidden; */
                    }
            }
            
            &.loaded {
                height:5rem;
                padding:0.5rem;
                &.info {
                    height:15rem;

                    .info {
                        opacity:1;
                    }
                }
            }
            &.title {
                /* display:flex; */

            }
            background-color:${colors[4]};
            border-radius:5px;
            margin:1rem 0;
            h2 {
                font-size: 1.6rem;
                /* text-transform: capitalize; */
            }
            p {
                font-size: 1rem;

            }
            .profil {
                display: flex;
                align-items:center;
                cursor:pointer;
            }
            .gear {
                position:absolute;
                top:2px;
                right:2px;
                transition:transform 0.1s linear;
                cursor:pointer;
                &:hover {
                    transform: rotate(45deg);
                }
            }
            &.inSetting {
                .settings {
                    display:flex ;
                }
                .gear {
                    transform: rotate(90deg);
                }
                h2 {
                    opacity:0.4;
                    user-select:none;
                }
                .dot {
                    display:none;
                }


            }
            .settings {
                display: none;
                height:4rem;
                /* justify-content: center; */
                align-items:center ;
                span {
                    width:50%;
                    display:flex;
                    align-items:center;
                    cursor:pointer;
                }
                span:first-of-type {
                    padding-right:1rem;
                    justify-content:flex-end ;
                    &.employee {
                        &:hover::before {
                            content:"Gør til Leder";
                            font-size:1rem;
                        }
                    }
                    &.leder {
                        &:hover::before {
                            content:"Gør til Medarbejder";
                            font-size:1rem;
                        }
                        svg {
                            transform:rotate(180deg);
                        }
                    }
                }
                span:last-of-type {
                    padding-left:1rem;
                    justify-content:flex-start ;
                    &:hover::after {
                        content:"DELETE";
                        font-size:1rem;
                    }
                }
                    
                
            }
            &.add {
                background-color:rgb(217 217 217 / 40%);
                display:flex;
                justify-content:center;
                align-items:center;
                cursor:pointer;
            }
            &.user.select {
                cursor:pointer;
                .gear {
                    display:none;
                }
            }
            &.muted {
                opacity:0.3;
                &:hover {
                    opacity:0.75;
                }
            }
            &.active .dot {
                border:1px solid ${colors[4]};
            }
            .dot {
                /* position:absolute;
                top:1rem;
                right:1rem; */
                display:inline-block ;
                width:2rem;
                height:1em;
                border-radius:5px;
                margin-right:1rem;
                transform:translate(0,0.1rem);

                &.fixed {
                    position: absolute;
                    bottom:0.5rem;
                    left:0.5rem;
                }
            }
            &.input {
                /* height:10rem; */
                overflow:hidden;
                height:0;
                padding:0 0.5rem;
                transition:height 0.2s linear, padding 0.2s linear;

                &.open {
                    height:5rem;
                    padding:0.5rem;
                    &.user {
                        height:10rem;
                    }
                }

                
            }
`

export default CardStyled;