import React from 'react';
import styled from 'styled-components';
import colors from '../data/colors';
import {Link } from 'react-router-dom'
import { FiExternalLink } from "react-icons/fi";
const Container = styled.div`
    height:8rem;             
    max-width:90vw;
    margin:1rem;
    padding:0 2rem;
    background-color:${colors[3]};
    border-radius:5px;
    /* display:flex; */
    #title {
        /* width:50%; */
        h1 {
            text-transform:uppercase;
            font-size:2.5rem;
    
            margin: 1rem 0rem 0rem 0rem;
            color:${colors[0]};
        }
        span {
            font-size: 1.6rem;
            margin-left:0.5rem;
        }
        a {
            color:${colors[1]};
            &:visited {
                color:${colors[1]};
            }
        }
    }
    #stats {
        /* width:50%; */
        margin-left:3rem;
        /* display:flex; */
        /* justify-content:space-around; */
        /* align-items:center; */
        span {
            color:${colors[1]} ;
            display:inline-block ;
            margin:2rem 3rem 2rem 0;
        }
    }

`

function CompanyTitle({name,data}) {
    return ( 
        <Container>
            <div id="title">
                <h1>{name ? name : null} <span> - User Management</span></h1>
                <Link to={`/dashboard/${name}`}>Results Dashboard <FiExternalLink /></Link>
            </div>
            <div id="stats">
                <span>Users: {data  ? data.members.length : null}</span>
                <span>Teams: {data  ? data.teams.length : null}</span>

            </div>

        </Container>
    );
}

export default CompanyTitle;