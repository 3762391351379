// import './App.css';

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import TotalPrecentBar from './components/TotalPrecentBar';
import questions from './data/questions.js'
import Question from './components/Qeuestion'
import Button from './components/Button';
import Result from './components/Result';
import PersonalRating from './components/PersonalRating';
import Submit from './components/Submit';
import scoreSystem from './data/scoreSystem.json'
import scoreBoardInit from './data/scoreBoard.json'

import { useSearchParams } from "react-router-dom";
import IntroText from './components/IntroText';

import RootContainer from "./styled/RootContainer"
import getUser from './functions/getuser'
import checkDate from './functions/checkDate';
import Dashboard from './components/Dashboard';

import { useParams } from 'react-router-dom';


const QuestionContainer = styled.div`
    width:100%;
    opacity:${props => props.progress === -1 ? "0" : "1"} ;
    transition: opacity 0.4s linear;
    /* transition: transform 0.4s cubic-bezier(0.38, 1.26, 0.75, 1.02); */
    transform: ${props => props.progress === -1 ? "translate(0,0) scale(0)" : "translate(0,0) scale(1)"};
`

function App() {
  
  const [precentUsed, setprecentUsed] = useState(0);
  const [precentArr, setprecentArr] = useState([0,0,0,0])
  const [progress, setProgress] = useState(-1);
  const [rating, setRating] = useState(5)
  const [scoreBoard, setScoreBoard] = useState(scoreBoardInit)
  const [result, setResult] = useState([])
  const [user, setUser] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [lang, setlang] = useState("DK");
  const [readyToAnswer, setReadyToAnswer] = useState(true);
  const [currentDate, setCurrentDate] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const { userId } = useParams();
  
  useEffect(() => {
    console.log("rendered")
    let isMounted = true

    // const getUserFromUrl = searchParams.get("user")

    if(userId && user === false) {
      
        const foundUserPromise = getUser(userId)
        foundUserPromise.then(response => {
          // console.log(response)
          if(response.message === "cant find user") {
            setUser("no user")
          }
          if(isMounted && response.message !== "cant find user") {
            setUser(response)
            
          }
        });
      };
      return () => {
       
        isMounted = false
      }
  }, []);
  // setSearchParams("")

  useEffect(()=> {
    if(user) {
      //GET LATEST ANSWER DATE
      if(user.answers.length > 0) {
        const isoDate = user.answers[user.answers.length-1].date
        setReadyToAnswer(checkDate(isoDate,1,'w'))
      } else {
        setReadyToAnswer(true)
      }
      
    }
    setHasFetched(true)
  },[user])

  useEffect(() => {
    if(!readyToAnswer) {
      const fetchedPrecentArr = [...user.answers[user.answers.length-1].spread]
      const fetchedRating = user.answers[user.answers.length-1].rating
      const fetchedResult = [user.answers[user.answers.length-1].result]
      const fecthcedDate = user.answers[user.answers.length-1].date
      console.log(fecthcedDate);
      setRating(fetchedRating)
      setprecentArr(fetchedPrecentArr)
      setResult(fetchedResult)
      setCurrentDate(fecthcedDate)
    } else {
      const today = new Date()
      const isoDate = today.toISOString()
      setCurrentDate(isoDate)

    }
  }, [readyToAnswer]);


  useEffect(() => {
      if(precentUsed === 100) {
        setProgress(4)
      }
  }, [precentUsed]);


  const questionComps = Object.keys(questions).map((q,index) => 
    <Question 
        key={q} 
        left={100 - precentUsed} 
        index={index} 
        id={q}
        str={questions[q][`str_${lang}`]}
        precent={precentArr[index]} 
        handler={handleSliders}
        progress={progress}
        setProgress={setProgress}
        keepScore={keepScore}
        lang={lang}
        
      />
  )

  const Dev = () => {
    return (
      <div>
            {/* DEV LOG */}
  
          {/* SCORE */}
          <p style={{position:"fixed",top:"1rem",left:"1rem"}}>score:{Math.max(...Object.keys(scoreBoard).map(res => scoreBoard[res]))}</p>
          
          {/* TYPE */}
          {result.map((res,index) => <span key={index} style={{position:"fixed",top:"3rem",left:`${index*2}rem`,margin:"1rem"}}>{res}</span>)}
          
          {/* KURVER */}
          <p style={{position:"fixed",top:"7rem",left:"1rem"}}>Kedsomhed: {precentArr[0] + precentArr[3]}</p>
          <p style={{position:"fixed",top:"10rem",left:"1rem"}}>Læring: {precentArr[2] + precentArr[3]}</p>
          <p style={{position:"fixed",top:"13rem",left:"1rem"}}>Mastering: {precentArr[0] + precentArr[1]}</p>
          <p style={{position:"fixed",top:"16rem",left:"1rem"}}>Motivation: {precentArr[1] + precentArr[2]}</p>
          
  
      </div>
    )
  }


  function handleSliders(val, index) {
    const newPrecent = [...precentArr]
    newPrecent[index] = val
    // keepScore(val,index)
    setprecentUsed(newPrecent.reduce((a,b)=> a+b))
    setprecentArr(newPrecent)
  }
  
  function keepScore() {
    
    const clamp = (num, max) => Math.min(Math.max(num, 0), max)
    
    const newScore = {...scoreBoardInit}
    precentArr.forEach((val, index)=> {
      // IGNORE EVERYTHING UNDER 10%
      if(val >= 10) {
        const scoreArea = clamp(Math.floor(val / 20),4); //10-20,20-40,40-60,60-80,80-100
        const str = `q${index+1}_${scoreArea}`
        // console.log(str)
        scoreSystem.forEach(resp => {
          if(resp[str] > 0) {
            newScore[resp.res] = newScore[resp.res] + resp[str]
          }
        })

      }
        // console.log(scoreSystem.map(res => res[str] > 0 ? {[res.res]:res[str]} : null))
    })
    // GET LARGEST SCORE
    const largestScore = Math.max(...Object.keys(newScore).map(res => newScore[res]))
    const result = []
    
    // GET ALL THE ANSWERS WITH THAT SCORE
    Object.keys(newScore).forEach(res => {
      if(newScore[res] === largestScore) {
        result.push(res)
      }
    })
    setScoreBoard(newScore)
    setResult(result)
    console.log(newScore, result)

  }


  useEffect(() => {
    console.log(progress)
    if(progress === 4) {
      window.scrollTo({top:0, behavior:'smooth'})
    }
  }, [progress]);

  // useEffect(() => {
  //   console.log(lang)
  // }, [lang]);
  // window.lang = "EN"
  // useEffect(()=> {
  //   let countTotal = 0;
  //   Object.keys(questions).forEach(id=> {countTotal = countTotal + questions[id].precent})
  //   setprecentUsed(clamp(countTotal))
  // },[questions])


    return (
      
      <RootContainer styleNO={{paddingBottom:`${progress < 5 && progress > -1 ? "calc(100vh - 27rem)" : "0"}`}}>
        
        {/* <button onClick={()=> setlang(lang === "EN" ? "DK" : "EN")}>{lang}</button> */}
        {/* {user.name ? user.name : null} */}
        
        {progress < 0 ? <IntroText readyToAnswer={readyToAnswer} setlang={setlang} lang={lang} hasFetched={hasFetched} user={user} setProgress={setProgress} /> : null}

        <Button lang={lang} spread={precentArr} precentUsed={precentUsed} progress={progress} setProgress={setProgress} hasUser={user && user !== "no user"}  />
        
        {/* {progress > -1 && progress < 5 ?
            <TotalPrecentBar precentUsed={precentUsed} progress={progress} />
            : null
          } */}

        {progress >= 0 ?
        <QuestionContainer progress={progress}>
            {questionComps}
        </QuestionContainer> : null}

        {/* <Dev /> */}

        {progress > 4 && user && user !== "no user" && readyToAnswer ? 
          <React.Fragment>
            <PersonalRating progress={progress} setProgress={setProgress} rating={rating} setRating={setRating}/> 
            <Submit lang={lang} user={user} answers={{spread:precentArr,rating:rating, result:result[0]}} progress={progress} setProgress={setProgress}/>
          </React.Fragment>
        : null}
        {progress > 6 ?
          <React.Fragment>
              <Result result={result} lang={lang} rating={rating}/> 
              {user && user !== "no user" ? <Dashboard lang={lang} answer={{spread:precentArr,rating:rating,date:currentDate}} user={user} showOldValues={!readyToAnswer}/> : null}
          </React.Fragment>
        : null}
      </RootContainer>
    );
  

}

export default App;
