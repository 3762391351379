import React from 'react';
import styled from 'styled-components';
import colors from '../data/colors';

const ButtonDiv = styled.div`
    
    width:${props => !props.readyToClick || props.progress > 4 ? "100%" : "30%"};
    height:${props => !props.readyToClick || props.progress > 4 ? "2.5rem" : "5rem"};
    background-color:${props => !props.readyToClick || props.progress > 4 ? colors[4] : colors[5]};
    border-radius:${props => !props.readyToClick || props.progress > 4 ? 0 : "5px"};
    overflow:hidden ;
    transition:all 0.2s ease-in-out;
    margin:0 auto;
    position:${props => !props.readyToClick || props.progress > 4 ? "sticky" :"relative"};
    /* z-index:200; */
    padding:${props => !props.readyToClick || props.progress > 4 ? "1rem 0" : "0"};
    top:0;
    margin-top:${props => !props.readyToClick || props.progress > 4 ? "0rem" :"1rem"};
    &:hover {
        ${props => props => props.readyToClick && props.progress < 5 ? `background-color:${colors[2]}`: null}
    }
    &:active {
        transform:${props => props.readyToClick ? "scale(0.9)" : "scale(1)"};
    }
    cursor: ${props => !props.readyToClick || props.progress > 4 ? "default" : "pointer"};
    h1 {
        position:absolute ;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        color:white;
    }
    #spread {
        justify-content:center ;
        height:${props => !props.readyToClick || props.progress > 4 ? "100%" : "10%"};
        display:flex;
        transition: height 0.5s ease-in-out ;
        // opacity:${props => props.precent < 100 ? "1" : "0"}; ;
        & > div {
            /* background-color:red ; */
            height:100%;

            display: flex;
            justify-content: center;
            align-items: center ;
            p{
                font-size:1rem;
                color:${colors[4]};
                opacity: ${props => props.progress > 4 ? 1 : 0};
                transition: opacity 1s linear ;
            }
        }
        /* div:first-of-type {
            border-top-left-radius:${props => props.precent < 100 ? "5px" : "5px"};
            border-bottom-left-radius:${props => props.precent < 100 ? "5px" : "5px"};
        }
        div:last-of-type {
            
            border-top-right-radius:${props => props.precent < 100 ? "5px" : "5px"};
            border-bottom-right-radius:${props => props.precent < 100 ? "5px" : "5px"};
        } */
    }
`

function Button(props) {

    const {precentUsed, progress, setProgress, spread, hasUser} = props

    const spreads = spread.map((p,index) => 
        <div key={index} style={{width:`${p}%`,backgroundColor:`${colors[index]}`}}>
            {p > 2 ? <p>{p}%</p> : null}
        </div>
    )

    function handleClick() {
        const jumptTo = hasUser ? 5 : 7;
        if(precentUsed === 100 && progress <= 4) {
            setProgress(jumptTo)
        }
    }

    return (
        progress > -1 ? 
        <ButtonDiv onClick={handleClick} readyToClick={precentUsed === 100 && progress === 4}precent={precentUsed} progress={progress}>
            <div id="spread">
                {spreads}
            </div>
            <h1>{precentUsed < 100 || progress < 4 ? precentUsed > 0 ? `${precentUsed}%` : null : progress === 4 ? props.lang === "EN" ? "NEXT" : "NÆSTE" : null}</h1>
        </ButtonDiv>
        : null
    );
}

export default Button;