import React from 'react';
import { GoPrimitiveDot } from "react-icons/go";
import colors from '../data/colors';
import checkDate from '../functions/checkDate';
function Status({answer}) {
    const isoDate = answer.date
    const hasAnswered = checkDate(isoDate,1,"w")
    return (
        <div className='status'>
            <p>{!hasAnswered ? "Done" : "Not Done"}</p>
            <div>
                <GoPrimitiveDot size={30} color={colors[hasAnswered ? 5 : 6]}/>
            </div>
        </div>
    );
}

export default Status;