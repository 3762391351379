import React from 'react';
import styled from 'styled-components';
import { FaGlobeEurope } from "react-icons/fa";
import { FaGlobeAmericas } from "react-icons/fa";
import { TiLocationArrow } from "react-icons/ti";
import { BsGlobe2 } from "react-icons/bs";
import { GrGlobe } from "react-icons/gr";
import colors from '../data/colors';
const Switcher = styled.div`
    position:absolute;
    ${props => props.pos.map(item => item)}
    /* bottom:3rem;
    left:3rem; */
    width:3rem;
    height:3rem;
    /* perspective:80rem; */
    cursor:pointer;
    transform:scale(1) ;
    &:active {
        transform:scale(0.95) ;
        /* cursor:progress; */
        /* cursor:${props => props.lang === "EN" ? "se-resize" : "ne-resize"}; */
    }
    #relative {
        
        position:relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* display: flex;
        justify-content:center ;
        align-content:center ; */
        #logos {
                position:relative ;
                width:100%;
                height:100%;
                /* transform: ${props => {
                    const val = props.lang === "EN" ? 1 : 0;
                    return `rotate3d(0,${val},0, 360deg)`
                }}; */
                /* transition: transform 1s cubic-bezier(0.63,-0.71, 0.47, 1.64); */
                /* transition: transform 0.8s cubic-bezier(0.53, 0.08, 0.58, 1.01);
                & > div {
                    position:fixed;
                    top:0;
                    left:0;
                    transition: opacity 0.5s cubic-bezier(0.53, 0.08, 0.58, 1.01) ;
        
                    &.en {
                        opacity: ${props => props.lang === "EN" ? 1 : 0};
                    }
                    &.dk {
                        opacity: ${props => props.lang === "EN" ? 0 : 1};
                    }
                } */
                
            }
            #strings {
                position:fixed;
                top:0;
                left:3.3rem;
                p {
                    transition: all 0.15s linear;
                    line-height:1.1;
                    color: ${colors[2]};
                    transform:scale(1);
                    transform-origin: left center ;
                    font-size:1.2rem ;
                    
                    &.active {
                        color: ${props => colors[props.color]};
                        transform:scale(1);
                    }
                    user-select:none ;
                }
            }

    }


`

function LanguageSwitcher({setlang,lang,color = 1,pos = ['top:3rem;','left:3rem;']}) {
    return (
        <Switcher pos={pos} color={color} lang={lang} onClick={()=> setlang(lang === "EN" ? "DK" : "EN")}>
            <div id="relative">
                <BsGlobe2 size={25} color={colors[color]} />
                <div id="strings">
                    <p className={lang === "EN" ? 'active' : ''}>English</p>
                    <p className={lang === "DK" ? 'active' : ''}>Dansk</p>
                </div>

            </div>
        </Switcher>
    );
}

export default LanguageSwitcher;