import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import colors from '../data/colors'
import { device } from './device'
const GlobalStyle = createGlobalStyle`

/* $blue: #1c3557d1; */

    ${reset}
    /* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
    html,body {
        font-size:62.5%;
        @media ${device.mobileL} {
            font-size:50%;
        }
    }
    body {
        background-color:${colors[4]};
        font-family: 'Roboto', sans-serif;
        /* background-color: var(--blue100); */
        
    }
    a,p,span,i,h1 {
        font-size: 1.5rem;
        line-height:1.5;
    }
`

export default GlobalStyle