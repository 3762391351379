import React from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import styled from 'styled-components';
import { GoArrowUp } from "react-icons/go";
import colors from '../data/colors';

const Tooltip = ({data}) => {
    console.log(data);
    return (
        <div style={{padding:"1rem",width:"20rem",backgroundColor:"white", color:"black"}}>   
            <div>
                {/* <p>{data}</p> */}
                <p>Her kan du læse om hvad det betyder at få en høj eller lave score her eller noget</p>
            </div>
        </div>
    )
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const RadialContainer = styled.div`
    width:${props => props.small ? "25%" : "40%" };
    height:${props => props.small ? "70px" : "150px" };
    position:relative;
    h1, h2,h3 {
        position:absolute;
    }
    h1 {
        font-size:${props => props.small ? "1rem" : "1.2rem" };
        top:0;
        left:50%;
        transform:translate(-50%,0%);
    }
    h2 {
        top:50%;
        left:50%;
        transform:translate(-50%,0%);
        font-size:${props => props.small ? "1rem" : "2rem" };
    }
`
const TrendStyled = styled.h3`
    top:75%;
    left:50%;
    /* left:${props => props.small ? "0" : "50%"}; */
    transform:translate(-60%,-50%) ${props => props.small ? "scale(0.6)": ""};
    font-size:1.2rem;
    color:${props => props.val <= 0 ? colors[5] : colors[6]};
    span {
        display:inline-block;
        /* transform-origin: center bottom; */
        transform:${props => props.val <= 0 ? "rotate(180deg)" : "rotate(0deg)"};
    }
`

export default ({ data , small/* see data tab */ }) => {
    const trend = data[0].data[0].y - data[0].trend

    // const dataNew = {}
    // dataNew.id = data[0].label
    // dataNew.data = [{
    //     x:"%",
    //     y:data[0]["1"]
    // }]
    // const dataNewArr = [dataNew]

    return (
        <RadialContainer small={small}>
            <h1>{data[0].id}</h1>
            <h2>{data[0].data[0].y}%</h2>
            {data[0].trend ? 
                <TrendStyled small={small} val={trend}><span><GoArrowUp size={20}/></span>{trend}</TrendStyled>
            : null
            }
            <ResponsiveRadialBar
                data={data}
                colors={data.map((d) => d.color)}
                enableRadialGrid={false}
                enableCircularGrid={false}
                valueFormat=">-.2f"
                startAngle={-132}
                endAngle={132}
                innerRadius={0.50}
                padding={0.25}
                cornerRadius={10}
                maxValue={100}
                margin={{ top: 15, right: 0, bottom: 0, left: 0 }}
                radialAxisStart={null}
                circularAxisOuter={null}
                legends={[]}
                tooltip={value => <Tooltip data={{value}} />}
            />
            
        </RadialContainer>
    )
}