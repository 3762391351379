import React from 'react';
import styled from 'styled-components';
import colors from '../data/colors.js';

import texts from '../data/texts.js'
import TextBox from '../styled/TextBox'
import LanguageSwitcher from './LanguageSwitcher.js';

const SubmitButton = styled.button`
    /* position: fixed; */
    /* bottom:10rem;
    left:50%; */
    padding: ${props => props.active ? "1rem": "0rem"};
    background-color:${colors[5]};
    border:none;
    border-radius: 5px;
    margin:5rem auto;
    overflow: hidden ;
    height:${props => props.active ? "3em" : "0"};
    color:white;
    display: block ;
    /* display:${props => props.active ? "block" : "none"} ; */
    transition: padding 0.4s ease-in-out, height 0.4s ease-in-out;
    cursor:pointer;
    &:active {
        transform:scale(0.9) ;
    }
    &:hover {
        background-color:${colors[2]} ;
    }
`

function IntroText(props) {
    const {lang, user, readyToAnswer} = props
    let index = user ? user !== "no user" ? 1 : 2 : 0;
    if(!readyToAnswer) {
        index = 3
    }

     return ( 
        <TextBox>
            {props.hasFetched ? 
            <React.Fragment>
                <h1>{lang === "EN" ? "Hi" : "Hej"} {user.name}</h1>
                <br></br>
                <p style={{whiteSpace:"break-spaces",marginTop:"1em"}}>
                    {texts[lang][index]}
                </p>
                <SubmitButton onClick={()=> props.setProgress(readyToAnswer ? 0 : 7)} active={true}>{lang === "EN" ? "CONTINUE" : "VIDERE"} </SubmitButton>
                {/* <LanguageSwitcher pos={['bottom:3rem;','left:3rem;']} color={4} setlang={props.setlang} lang={lang}/> */}
            </React.Fragment> :
                <h1>loading...</h1>
            }

        </TextBox>
    );
}

export default IntroText;