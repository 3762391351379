import React, { useState, useEffect } from 'react';
import teamColors from '../data/teamColors';
import { BsFillPeopleFill } from "react-icons/bs";
import { BsPlus } from "react-icons/bs";
import AddToCompany from './AddToCompany';
import CardStyled from '../styled/CardStyled';
import { TiDeleteOutline } from "react-icons/ti";

function Card({deleteTeam,team, select, active,color}) {
    const [loaded, setloaded] = useState("")

    useEffect(() => {
        setTimeout(()=>{
            setloaded("loaded")
        },20)
    }, []);
    function handleClickDelete() {
        console.log("DELETE");
        deleteTeam(team)
    }
    return (
        <CardStyled 
            className={`${loaded} card clickable ${active ? "active" : "" }`}
            onClick={()=>select(active ? null : team.name)}
            style={active ? {backgroundColor:color} : null}
        >
            <h2><span className='dot' style={{backgroundColor:color}}></span>{team.name} </h2>
           
            <p>members:{team.members.length}</p>
            <div className="gear" onClick={handleClickDelete}>
                <TiDeleteOutline size={15}/>
            </div>
        </CardStyled>
    );
}



function Teams({teams,deleteTeam, select, handleAddTeam,selectedTeamName}) {

    const [adding, setadding] = useState(false);
    const [cards, setcards] = useState();
    useEffect(() => {
        if(teams) {

            const comps = teams.map((team,index) => 
                <Card 
                    deleteTeam={deleteTeam}
                    key={index}
                    team={team}
                    color={teamColors[index]}
                    active={team.name === selectedTeamName} 
                    select={select} 
                />)
            setcards(comps)
        }
        
    }, [teams,selectedTeamName]);

    function handleClick(e) {
        if(e.target.classList.contains('manageBox')) {
            select(null)
        }
    }


    return ( 
        <div className='manageBox' onClick={handleClick}>
            <h1>TEAMS</h1>
            {cards}
            {adding ? 
            <AddToCompany handleAdd={handleAddTeam} setAddState={setadding} />
            : null}
            <CardStyled className="add loaded" onClick={()=>setadding(!adding)}>
                {adding ?
                    <h1>CANCEL</h1>
                :
                    <>
                        <BsFillPeopleFill size={40}/>
                        <BsPlus size={40}/>
                    </>
                }
            </CardStyled>

        </div> 
    );
}

export default Teams;