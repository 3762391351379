export default async (userId, role) => {

    const url = `${process.env.REACT_APP_REST_API_URL}/user/role/${userId}`
    let fetchedData
    console.log(userId)
    const sendObj = {role:role}
    const fetchOptions = {
        method: 'PATCH',
        headers: {
            "Content-Type" : "application/json"
        },
        body: JSON.stringify(sendObj)

    }

    try {
        await fetch(url, fetchOptions).
        then(response => response.json()).
        then(message => fetchedData = message)
    } catch(err) {
        console.error(err)
        fetchedData = err
    }
    return fetchedData
}