export default async (teamsObj,companyname) => {
    const url = `${process.env.REACT_APP_REST_API_URL}/company/updateteams/${companyname}`

    // let foundUser = {};
    let fetchedData;
    try {
        await fetch(url, {
            method:"PATCH",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify(teamsObj)
          })
          .then(res => res.json())
          .then(data => fetchedData = data)
          .catch(err => {
            console.log(err)
          })

    } catch(err) {
        console.log("TRY ERROR" + err)
        fetchedData = false
    }
    return fetchedData
    
}