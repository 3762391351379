export default {
    q1: {
        str_EN:"How much of your time is spent doing something you are good at but don't really like?",
        str_DK:"Hvor meget af din tid bruger du på noget, du er god til men ikke motiverer dig?",
        precent:0
    },
    q2: {
        str_EN:"How much of your time is spent doing something you are good at and love to do?",
        str_DK:"Hvor meget af din tid bruger du på noget, du både er god til og motiverer dig?",
        precent:0
    },
    q3: {
        str_EN: "How much of your time is spent doing something you don't yet master but would really like to learn?",
        str_DK: "Hvor meget af din tid bruger du på noget, du endnu ikke har lært, men virkelig gerne vil lære?",
        precent:0
    },
    q4: {
        str_EN: "How much of your time is spent doing something you don't yet master and you're not really interested in learning?",
        str_DK: "Hvor meget af din tid bruger du på noget, du endnu ikke har lært, men heller ikke er specielt interesseret i at lære?",
        precent:0
    }
}
    
    
