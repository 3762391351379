export default (check, interval,span = "w") => { //w(eek) m(onth) d(ays)
    const msTable = {
        "w":604800016.56,
        "m":2629800000,
        "d":86400000
      }
      const checkDate = new Date(check);
      const checkTimeStamp = checkDate.getTime()
      const nowdate = new Date();
      const nowtimestamp = nowdate.getTime()
      return ((nowtimestamp - checkTimeStamp) > (msTable[span] *  interval))

}
