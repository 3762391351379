import moment from 'moment'

export default (arr,a, b) => {
    // const obj = {
    //     date:null,

    // }
    // console.log(arr);

    return arr.map(arr => (
        {
            value:arr.spread[a] + arr.spread[b],
            date: moment.utc(arr.date,"YYYY-MM-DD\THH:mm:ss\Z").format("DD-MM-YYYY")
        }
    ))
}