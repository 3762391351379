import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import colors from '../data/colors';

const BarContainer = styled.div`
    width:100%;
    /* background-color:${colors[2]}; */
    height:${props => props.progress > 3 && props.precent === 100 ? "0rem" : "5rem"};
    display: flex;
    align-items:center;
    transition:height 0.21s ease-in-out;

    #used {
        width:${props => `${100 - props.precent}%`};
        height:2.5rem;
        background-color:${colors[1]};
        border-radius:5px;
        /* border-top */
        border-top-right-radius: ${props => props.precent == 0 ? "5px" : "0px"};
        border-bottom-right-radius: ${props => props.precent == 0 ? "5px" : "0px"};
        display:flex;
        align-items: center;
        transition: border 0.8s linear;
        /* border-top-right-radius:${props => props.precent < 1 ? "5px" : "5px"}; */
        /* border-bottom-right-radius:${props => props.precent < 1 ? "5px" : "5px"}; */
        h1 {
            text-align:center;
            margin:auto;
            color:${colors[4]};
            display:${props => props.progress > 3 && props.precent === 100 ? "none" : "block"}; ;
            /* height:5rem; */
            
        }
    }
    #total {
        border-radius:5px;
        border-top-left-radius: ${props => props.precent == 100 ? "5px" : "0px"};
        border-bottom-left-radius: ${props => props.precent == 100 ? "5px" : "0px"};
        width:${props => `${props.precent}%`};
        background-color:${colors[3]};
        transition: height 0.2s ease-in-out;
        height:${props => props.progress === 4 && props.precent === 100 ? "0px" : "2.5rem"};
    }
`


function TotalPrecentBar(props) {

    const [active, setactive] = useState(false);

    const {precentUsed, progress} = props


    // useEffect(() => {
    //     if(!active) {
    //         setactive(true);
    //         setTimeout(()=>{
    //             setactive(false)
    //         },500)
    //     }
    // }, [precentUsed]);

    return ( 
        <div style={{position:'sticky',top:0,backgroundColor:colors[4],zIndex:100}}>
            <BarContainer active={active} precent={precentUsed} progress={progress}>
                <div id="used">

                <h1>{100 - precentUsed}%</h1>

                </div>
                <div id="total"></div>
            </BarContainer>
        </div>
     );
}

export default TotalPrecentBar;