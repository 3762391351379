import React, { useEffect, useRef } from 'react';
import answers from '../data/answers.json'
import { BsFillPersonFill } from "react-icons/bs";
import Status from './Status';

import TrivselTrend from './TrivselTrend';



function UserBar(props) {

    // const [hasContent, sethasContent] = useState(false);

    const {user, index, handleClick, active, lang} = props

    const ref = useRef()

    // useEffect(() => {
    //     sethasContent(user.answers.length > 0)
    //     console.log(user.name);
    // }, []);

    const hasContent = user.answers.length > 0
    const lastAnswer = user.answers.length - 1

    const userName =  user.name.trim().split(/\s+/);

    useEffect(() => {
        if(active) {
            ref.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })
        }
}, [active]);


    // console.log(`${user.answers[lastAnswer].result}_${lang}`); 
    return (
        
        <div ref={ref} className={`bar ${active ? 'active' : ""}`} onClick={() => handleClick(index)}>
            <div className="profil" style={{width:"20%"}}>
                <BsFillPersonFill size={40}/>
                {userName.map((name,index) => <span key={index}>{name}</span>)}
            </div> 
            {hasContent ? 
                <React.Fragment>
                    <span>"{answers[`${user.answers[lastAnswer].result}_${lang}`].title}"</span> 
                    <TrivselTrend active={active} value={user.answers[lastAnswer].rating} user={user} />
                    <Status answer={user.answers[lastAnswer]}/>
                </React.Fragment>
            : 
                <span>"N/A"</span>
            }
        </div>

    );
}

export default UserBar;