import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import RootContainer from "../styled/RootContainer"
import {Link, useParams} from "react-router-dom"
import getOneCompany from '../functions/getOneCompany';
import Teams from '../components/Teams';
import Users from '../components/Users';
import colors from '../data/colors';
import updateTeams from '../functions/updateTeams';
import addTeam from '../functions/addTeam';
import { BiError } from "react-icons/bi";
import putUser from '../functions/putUser';
import updateCompanyMembers from '../functions/updateCompanyMembers'
import deleteUser from '../functions/deleteUser';
import deleteTeam from '../functions/deleteTeam';
import editRole from '../functions/editRole';
import CompanyTitle from '../components/CompanyTitle';
import { device } from '../styled/device';

const DashBoardContainer = styled(RootContainer)`
    width:90vw;
    max-width:90rem;
    transition:filter 0.2s linear;
    filter: ${props => props.error ? "blur(10px)" : "blur(0)"};
    
    & > div {
        display:flex;
        
        @media ${device.mobileL} {
            flex-direction: column;
        }        
        /* @media ${device.laptop} {
            flex-direction: row;
        } */
        
        .manageBox {

            @media ${device.mobileL} {
                width:calc(100% - 6rem);
                height:24vh;
            }
            @media ${device.laptop} {
            }
            width:33%;
            height:80vh;
            overflow:scroll;
            background-color: ${colors[3]};
            margin:1rem;
            padding:2rem;
            border-radius:5px;
            
            .clickable {
                cursor: pointer;
                &:active {
                    transform:scale(0.96);
                }
            }
        }
    }
`

const Error = styled.div`
    position: fixed;
    top:50%;left:50%;
    transform:translate(-50%,-50%);
    width:33vw;
    height:20vh;
    background-color:${colors[3]};
    border-radius:15px;

    & > div {
        position:relative;
        height:100%;width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        
        #button {
            display:flex;
            justify-content:center;
            align-items:center;
            position:absolute;
            bottom:2rem;
            left:50%;
            transform:translate(-50%,0);
            width:5rem;
            height:1rem;
            & > div {
                background-color: ${colors[2]};
                padding:0.5rem 1rem;
                margin:0.5rem;
                cursor:pointer;

            }
        }
    }
`

// function CompanySelect({name}) {
//     return (
//         <Link to={`/manage_users/${name}`}>
//             <button>{name}</button>
//         </Link>
//     );
// }

function ErrorComp({message,prompt,cb}) {

    const buttonPrompts = prompt.map((str,index)=> 
        <div key={index} onClick={cb[index]}><p>{str}</p></div> 
    )
    return (            
    <Error>
        <div>
            <div id="icon"><BiError size={30}/></div>
            <h1>{message}</h1>
            <div id="button">
                {buttonPrompts}
            </div>
        </div>
    </Error> );
}





function Management() {
    // const [links, setLinks] = useState();
    const { company } = useParams();
    const [companyData, setCompanyData] = useState();
    const [selectedTeamName, setselectedTeamName] = useState();
    const [selectedTeamObj, setselectedTeamObj] = useState();
    const [teamRooster, setTeamRooster] = useState();
    const [error, setError] = useState(null);
    const [teams, setTeams] = useState()
    const [members, setMembers] = useState()
    useEffect(() => {
        
        if(company) {
            fetchCompanyData(company)
        }

    }, []);

    useEffect(() => {
        if(companyData) {
            setTeams(companyData.teams)
            setMembers(companyData.members)
        }
        if(companyData && !teamRooster) {

            let allTeams = {}
            companyData.teams.forEach(t => {
                allTeams[t.name] = [...t.members]
            })
            setTeamRooster(allTeams)
            console.log(allTeams);
        }

    }, [companyData]);
    
    
    useEffect(() => {
        // if(companyData && selectedTeamName) {
        //     const currentRoster = companyData.teams.filter(t => t.name === selectedTeamName)
        //     setselectedTeamObj(currentRoster)
        //     console.log(currentRoster[0].members);

        // }
    }, [selectedTeamName, companyData]);

    function fetchCompanyData (name) {
        const fecthPromise = getOneCompany(name)
        fecthPromise.then(res => {
            console.log(res);
            setCompanyData(res)
        })
    }

    function updateTeamRooster(team, memberId) {
        const updatedTeamRooster = {...teamRooster}

        let foundIn = false;
        //REMOVE CLICKED USER FROM ALL TEAMS
        Object.keys(updatedTeamRooster).forEach(teamName => {
            const teamIDs = [...updatedTeamRooster[teamName]]
            // console.log(team === teamName, updatedTeamRooster[teamName].includes(memberId));
            if(updatedTeamRooster[teamName].includes(memberId) && teamName === team) {
                foundIn = true
            }
            
            
            const filteredIDs = teamIDs.filter(id => id !== memberId)
            updatedTeamRooster[teamName] = filteredIDs
        })
        //ADD CLICKED USER TO TEAM IF ITS A NEW TEAM
        if(!foundIn) {
            updatedTeamRooster[team].push(memberId)
        }
        setTeamRooster(updatedTeamRooster)
    }

    useEffect(() => {
        if(companyData && teamRooster) {
            console.log(teamRooster);
            const updatedCompanyData = updateTeams(teamRooster,company)
            updatedCompanyData.then(res => setCompanyData(res))
        }
    }, [teamRooster]);

    // function addMember(team,member) {
    //     console.log(member, team);
    //     const promise = addMemberToTeam(company,team,[member])
    //     console.log(promise);
    //     promise.then(res => {
    //         fetchCompanyData(company)
    //     })
    //     // promise.then(res => console.log(res))
    // }
    function handleAddTeam(team) {
        // setTeamRooster(null)
        // console.log(team);
        const newTeamObj = [{name:team[0].name.toLowerCase()}]
        const newCompanyData = addTeam(newTeamObj,company)
        newCompanyData.then(data => {
            if(data.message) {
                setError(<ErrorComp 
                            message={data.message} 
                            prompt={["close"]} 
                            cb={[()=>setError(null)]}
                        />)
                // return
            }  else {
                setTeamRooster(null)
                setCompanyData(data)

            }
            console.log(data);
        })

    }
    function handleAddUser(user) {
        console.log(user);
        const userObj = {...user[0],company:company}
        const newCompanyData = putUser(userObj)

        newCompanyData.then(data => {
            if(data.message) {
                setError(<ErrorComp 
                    message={data.message} 
                    prompt={["close"]} 
                    cb={[()=>setError(null)]}
                />)
                return
            }  else {
                
                const updatedCompanyData = updateCompanyMembers(company)
                updatedCompanyData.then(newdata => {
                    setTeamRooster(null)
                    setCompanyData(newdata)
                })
            }
        })

    }
    function handleDeleteUser(id) {
        console.log(id);

        const yes = (id)=> {
            const updatedCompanyData = deleteUser(id)
            updatedCompanyData.then(data => {
                if(data.message) {
                    setError(<ErrorComp 
                        message={data.message} 
                        prompt={["close"]} 
                        cb={[()=>setError(null)]}
                    />)
                    const updatedCompanyData = updateCompanyMembers(company)
                    updatedCompanyData.then(newdata => {
                        setTeamRooster(null)
                        setCompanyData(newdata)
                    })
                }

            })

        }

        setError(
            <ErrorComp 
                message={`You sure you want to delete?`} 
                prompt={["YES","NO"]} 
                cb={[()=>yes(id),()=>setError(null)]}
            />
        )
    }
    function handleDeleteTeam(team) {
        setselectedTeamName(null)
        const yes = (t) => {
            console.log(t);
            const updatedCompanyData = deleteTeam(company,t)
            updatedCompanyData.then(data => {
                if(data.message) {
                    setError(<ErrorComp 
                        message={data.message} 
                        prompt={["close"]} 
                        cb={[()=>setError(null)]}
                    />)
                } else {
                    setTeamRooster(null)
                    setCompanyData(data)
                    setError(null)
                    
                }
            })

        }
        setError(
            <ErrorComp 
                message={`You sure you want to delete "${team.name}?"`} 
                prompt={["YES","NO"]} 
                cb={[()=>yes(team.name),()=>setError(null)]}
            />
        )
    }

    function handleEditRole(id,role) {
        console.log(id,role);
        const updatedCompanyData = editRole(id,role)
        updatedCompanyData.then(data => {
            if(data.error) {
                setError(<ErrorComp 
                    message={data.message} 
                    prompt={["close"]} 
                    cb={[()=>setError(null)]}
                />)
            } else {
                const updatedCompanyData = updateCompanyMembers(company)
                updatedCompanyData.then(newdata => {
                    setTeamRooster(null)
                    setCompanyData(newdata)
                })
            }
        })

    }


    useEffect(() => {
        console.log(selectedTeamName)
    }, [selectedTeamName]);
    
    const userProps = {
        members:members,
        selectMode:selectedTeamName ? true : false,
        selectedTeamName:selectedTeamName,
        add:updateTeamRooster,
        teamRooster:teamRooster,
        handleAddUser:handleAddUser,
        deleteUser:handleDeleteUser,
        editRole:handleEditRole
    }

    return (
        <>
           
            <DashBoardContainer error={error !== null}>
                {/* {companyData && teamRooster ?  */}
                <>
                    <CompanyTitle name={company} data={companyData}/>
                    <div>
                        <Users 
                            mode={"leder"}
                            title={"Managers"}
                            // functions={{...userProps}}
                            members={members} 
                            selectMode={selectedTeamName ? true : false}
                            selectedTeamName={selectedTeamName}
                            add={updateTeamRooster}
                            teamRooster={teamRooster}
                            handleAddUser={handleAddUser}
                            deleteUser={handleDeleteUser}
                            editRole={handleEditRole}
                        />
                        <Teams 
                            selectedTeamName={selectedTeamName} 
                            select={setselectedTeamName}
                            teams={teams}
                            handleAddTeam={handleAddTeam}
                            deleteTeam ={handleDeleteTeam}
                        />
                        <Users 
                            mode={"employee"}
                            title={"Users"}
                            // functions={{...userProps}}
                            members={members} 
                            selectMode={selectedTeamName ? true : false}
                            selectedTeamName={selectedTeamName}
                            add={updateTeamRooster}
                            teamRooster={teamRooster}
                            handleAddUser={handleAddUser}
                            deleteUser={handleDeleteUser}
                            editRole={handleEditRole}
                        />
                    
                    </div>
                </>
                {/* : null} */}
            </DashBoardContainer>
            {error}
        </>
    );
}

export default Management;