export default async (input) => {

    const url = `${process.env.REACT_APP_REST_API_URL}/user/`
    let fetchedData = {message:null,data:null};
    console.log("POSTING")
    const fetchOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    };

    try {
        await fetch(url, fetchOptions).
        then(response => response.json()).
        then(data => fetchedData = data)
    } catch(err) {
        console.error(err)
        fetchedData = err
    }
    return fetchedData

}   