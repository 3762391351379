export default async (name) => {
    const url = `${process.env.REACT_APP_REST_API_URL}/company/${name}`
    console.log("GETTING COMPANY");
    // let foundUser = {};
    let fetchedData;
    try {
        await fetch(url)
            .then(resp => resp.json())
            .then(data => {
                console.log(data)
                fetchedData = data;
            })
            .catch(err => {
                console.log("ERROR" + err)
                fetchedData = false
            })

    } catch(err) {
        console.log("TRY ERROR" + err)
        fetchedData = false
    }
    return fetchedData
    
}