export default (label, arr, color) => (
    {
        "id": label,
        "color": color,
        "data": arr.map((k, index) => {
            let datapoint = {}
            datapoint.x = k.date
            datapoint.y = k.value
            return (
                datapoint
            )
        })
    }
)
