export default async (userId) => {
    const url = `${process.env.REACT_APP_REST_API_URL}/user/${userId}`
    let fetchedData = {message:null,data:null};
    console.log("POSTING")
    const fetchOptions = {
        method: 'DELETE',
    };

    try {
        await fetch(url, fetchOptions).
        then(response => response.json()).
        then(data => fetchedData = data)
    } catch(err) {
        console.error(err)
        fetchedData = err
    }
    return fetchedData

}   