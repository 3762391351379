export default {
    "DK": [
        "Velkommen til MOTIVATOR. 5 hurtige spørgsmål - data og indsigt tilbage til dig med det samme.\n\nHvis du er del af et test team, skal du bruge det link der er blevet sendt til dig.\n\nHvis du ikke er en del af testen, men bare vil prøve produktet, så klik videre.",
        "Velkommen til MOTIVATOR\n\nMotivation, læring og flow ift. dine arbejdsopgaver\n\nSkal vi kigge på det?",
        "Kan ikke finde bruger. Check at du har brugt det rigtige link",
        "Du har allerede indsendt dit svar. Klik VIDERE for at se dit resultat."
    ],
    "EN": [
        "If you have been asked to do this test by your supervisor, please open the link that they send you, or else your result will not be saved. If you are not part of a survey and just want to take the test for yourself, please click continue",
        "Welcome to Motivator, please click to continue",
        "Can't find the user, please make sure you've clicked the right URL that was sent to you",
        "You've already completed the survey for this time period. We will notify when next survey is open"
    ]
}