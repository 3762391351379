import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './styled/GlobalStyles';
import AddUsers from './routes/AddUsers'
import LederDashboard from './routes/LederDashboard';
import Management from './routes/Management';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path='/' exact element={<App />} />
          <Route path='/:userId' element={<App />} />
          <Route path='/addusers' element={<AddUsers />} />
          <Route path='/dashboard/' element={<LederDashboard />} />
          <Route path='/dashboard/:company' element={<LederDashboard />} />
          <Route path='/manage_users' element={<Management />} />
          <Route path='/manage_users/:company' element={<Management />} />
        </Routes>

      </Router>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
