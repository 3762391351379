import styled from "styled-components"
import colors from "../data/colors"

const Slider = styled.div`

        position: relative;
        height:4rem;
        /* margin:2rem 0; */
        .bar {
            width: 100%;
            height: 1rem;
            position: absolute;
            top: 0.5rem;
            background-color: ${colors[1]};
            z-index:10;
            border-top-right-radius:15px;
            border-bottom-right-radius:15px;
            box-shadow: inset -1px 1px 5px 0px #00000069;
        }
        input {
            position:absolute;
            z-index:50;
            -webkit-appearance: none;
            width: 100%;
            height: 2rem;
            // height: 1rem;
            // background: #d3d3d3;
            outline: none;
            opacity: 1;
            -webkit-transition: .2s;
            transition: opacity .2s;
            margin: 0 auto;
            display: block;
            border-radius: 5px;
            background-color: transparent;
            /* background-image: ${props => `linear-gradient(90deg,${colors[1]},${colors[1]}`}; */
            
            &::-webkit-slider-runnable-track {
              width: 100%;
              height: 1rem;
              cursor: ${props => !props.clickAble ? "default" : "pointer"};
              outline: none;
              border:none;
              border-radius: 15px;
              background-size: 10% 100%;
            }
            &:hover {
              opacity: 1;
            }
            
            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 4rem;
              height: 4rem;
              background: ${props => props.active ? colors[5] : colors[2] } ;
              transform: translate(0,-1.5rem) ;
              border-radius: 5px;
              cursor: ${props => !props.clickAble ? "default" : "pointer"};
              // border: solid $blue100 2px
              /* box-shadow: inset 0px 0px 15px 8px #ff6f73; */
              margin: 0rem 0  0;
              /* color: #F16568; */
            }
            &:after {
              content: ${props => `${props.string}`};
            }
        }

`

export default Slider
