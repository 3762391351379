import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components';
import colors from '../data/colors';
import Slider from '../styled/Slider';

const Container = styled.div`
    box-shadow: ${props => props.active ? "2px 2px 9px #7b8798" : "none"} ;
    width:calc(100% - 6rem);
    padding:${props => props.progress > 4 ? "0" : "1rem 3rem 1rem 3rem"};
    margin:${props => props.progress > 4 ? "0 auto" : "1.5rem auto"};
    background-color:${props => props.active ? `${colors[1]}`: `${colors[3]}`};
    border-radius:15px;
    color:${colors[4]};
    /* min-height:${props => props.progress > 4 ? "0rem" : "15rem"}; */
    max-height: ${props => props.progress > 4 ? "0rem" : "15rem"};
    transition: all 0.2s ease-in-out;
    /* transition: background-color 0.5s linear, max-height 0.3s ease-in-out, margin 0.21s ease-in-out; */
    overflow:hidden;
    .text {
        padding:1rem 0 2rem 0;
        opacity:${props => props.active && props.progress < 5? 1 : 0};
        transform:${props => props.active && props.progress < 5 ? "scale(1)" : "scale(0.7)"};
        transition: all 0.2s ease-in-out;
        /* color:white; */
    }
    .precent {
        /* color:white; */
        text-align:right;
    }
`



function Question(props) {

    const [max, setmax] = useState(100);
    const [gradient, setgradient] = useState(`linear-gradient(90deg,${colors[2]} 0%, ${colors[2]} 0%, transparent 0%, transparent 100%)`);
    // const [active, setActive] = useState(false)
    // const [active, setactive] = useState(false);
    const [disabled,setDisabled] = useState(true)

    const ref = useRef()

    const {progress, index} = props

    function handleChange(e) {
        const value = clamp(parseInt(e.target.value),max);
        props.handler(value,index)

    }

    function handleUp() {
        props.keepScore()
        if(index === progress) {
            props.setProgress(index + 1)
        }
        if(!disabled && progress > index && progress <= 3) {
            setDisabled(true)
        }
    }
    function handleDown() {
        if(disabled && progress > index) {
            console.log("down")
            setDisabled(false)
        }
    }
    useEffect(()=>{
        // if(index === progress) {
        //     // ref.current.scrollIntoView({behavior: "smooth"})
        //     // ref.current
        //     const yOffset = -115;
        //     const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset
        //     window.scrollTo({top:y, behavior:'smooth'})
        // }
        // setactive(index === progress || progress > 3)
        setDisabled(index !== progress)
        if(progress === 4) {
            setTimeout(() => {
                setDisabled(false)
            }, 200 - (index*50));
        }
        // setDisabled(index !== progress && progress <= 3)
    },[progress])

    useEffect(()=>{
        setmax(props.left + props.precent)
        setgradient(`linear-gradient(90deg,${colors[2]} ${props.precent}%, ${colors[2]}  ${props.precent}%, transparent 0%, transparent 100%)`)
    },[props.left])
    
    const clamp = (num, max) => Math.min(Math.max(num, 0), max)
    return (
        <Container active={!disabled} progress={progress} >
            <React.Fragment>
                <div className='text' ref={ref}>
                    <p>{props.str}</p>
                </div>
                {/* <h1>current:{props.precent} / left:{props.left} / max:{max}</h1> */}
                <Slider active={!disabled} clickAble={progress >= index} >
                    <div className='bar'></div>
                    <input 
                        type="range"
                        min={0}
                        max={100}
                        value={props.precent}
                        onChange={handleChange}
                        onMouseDown={handleDown}
                        onMouseUp={handleUp}
                        onTouchEnd={handleUp}
                        style={{ backgroundImage: gradient }}
                        disabled={progress < index}
                    />

                </Slider>
                <p className="precent">{props.precent} %</p>

            </React.Fragment>

        </Container>
    )
}

export default Question