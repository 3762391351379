import React from 'react';
import styled from 'styled-components';
import colors from '../data/colors';
import patchUser from '../functions/patchUser';

const SubmitButton = styled.button`
    padding: ${props => props.active ? "1rem": "0rem"};
    background-color:${colors[5]};
    border:none;
    border-radius: 5px;
    margin:0 auto;
    overflow: hidden ;
    height:${props => props.active ? "3em" : "0"};
    color:white;
    display: block ;
    /* display:${props => props.active ? "block" : "none"} ; */
    transition: padding 0.4s ease-in-out, height 0.4s ease-in-out;
    cursor:pointer;
    &:active {
        transform:scale(0.9) ;
    }
    &:hover {
        background-color:${colors[2]} ;
    }
`

function Submit(props) {

    
    const {user: {_id}, answers, setProgress, progress} = props

    const handleSubmit = () => {
        // const cb = (resp) => {
        //     console.log(resp)
            

        // }
        patchUser(_id,answers,setProgress)
    }

    return ( 
        <SubmitButton active={progress === 6} onClick={()=> handleSubmit()}>
            {props.lang === "EN" ? "SUBMIT RESULT" : "SEND RESULTAT"}
        </SubmitButton> 
    );
}

export default Submit;