import React from 'react';
import styled from 'styled-components';
import Graph from './Graph';
import { BiError } from "react-icons/bi";
import { device } from '../styled/device';

const GraphsContainer = styled.div`
    height:300px;
    width:80vw;
    max-width:60rem;
    margin:0 auto;

    @media ${device.mobileL} {
        width:100%;
    }

    //dev
    overflow:scroll ;
    #NA {
        height:30vh;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center ;
    }
`

function DashboardGraphs(props) {

    const {allUsers, selectIndex, lang} = props
    
    const currentUser = allUsers[selectIndex]
    console.log(currentUser);

    return (
        <GraphsContainer>
            {currentUser.answers.length > 0 ?
            <Graph 
                lang={lang} 
                user={allUsers[selectIndex]} 
                small={currentUser.answers.length > 1}
            />
            :
            <div id="NA">
                <BiError size={30}/>
                <h1>NO DATA</h1>
            </div>
            }

        </GraphsContainer>
    );
}

export default DashboardGraphs;